import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import { headline } from '../layout/base.styles'

export const SectionContainer = styled.section`
  background-color: ${NeutralColors.GreyPaper};
  padding: 80px 0;
`

export const Header = styled.h2`
  text-align: center;
  ${headline.size3};
`
export const RemainingVideos = styled.div`
  padding-top: 40px;
`
