import { Headline } from '@typeform/ginger/dist/components/typography'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled, { css } from 'styled-components'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'

export const FONT_WEIGHT_NORMAL = fontWeight.regular
export const FONT_WEIGHT_BOLD = fontWeight.regular
export const FONT_WEIGHT_BOLDER = fontWeight.medium

export const StyledHeadline = styled(Headline)`
  font-weight: ${FONT_WEIGHT_BOLD};
  font-size: ${fontSize.xLarge};
  line-height: ${lineHeight.small};

  ${mediaQueries.sm`
    font-size: ${fontSize.x2Large};
    line-height: ${lineHeight.xSmall};
    letter-spacing: -0.01em;
  `}

  ${mediaQueries.md`
    font-weight: ${FONT_WEIGHT_NORMAL};
    font-size: ${fontSize.x3Large};
    line-height: ${lineHeight.xSmall};
  `}
`

export const BoldBodyCss = css`
  font-feature-settings: 'ss02' on;
  font-style: normal;
  font-weight: ${FONT_WEIGHT_BOLD};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};
`

export const BodyCss = css`
  ${BoldBodyCss}
  ${mediaQueries.md`
    font-weight: ${FONT_WEIGHT_NORMAL};
    font-size: ${fontSize.medium};
    line-height: ${lineHeight.medium};
  `}

  ${mediaQueries.lg`
    letter-spacing: 0em;
  `}
`
