import { SearchIcon as EchoSearchIcon } from '@typeform/echo-assets'

import { TSearchIconProps } from '../types'

import { SearchIconContainer } from './search-icon.styles'

export const SearchIcon = (props: TSearchIconProps) => (
  <SearchIconContainer {...props}>
    <EchoSearchIcon />
  </SearchIconContainer>
)
