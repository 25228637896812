import { NAMESPACES } from 'constants/locales'

import { useListKeyboardNav } from 'components/help/hooks'
import useTranslation from 'next-translate/useTranslation'
import PropTypes from 'prop-types'
import React from 'react'

import Suggestion from './suggestion'
import { SuggestionContainer, SuggestionHeader } from './suggestions.styles'

const Suggestions = ({ suggestions, showSuggestions }) => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const activeIndex = useListKeyboardNav(suggestions.length, showSuggestions)

  if (!showSuggestions) {
    return null
  }

  return (
    <SuggestionContainer>
      <SuggestionHeader onClick={e => e.stopPropagation()}>
        {t('help-center.suggestions.top-articles')}
      </SuggestionHeader>
      {suggestions.map((suggestion, i) => (
        <Suggestion
          data={suggestion}
          key={suggestion.title}
          isActive={i === activeIndex}
        />
      ))}
    </SuggestionContainer>
  )
}

Suggestions.propTypes = {
  showSuggestions: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.string,
      snippet: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

Suggestions.defaultProps = {
  suggestions: [],
}

export default Suggestions
