import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import styled from 'styled-components'
import { UIPalette } from '@typeform/ginger/dist/constants/colors'

export const StyledHeader = styled.section`
  background: ${UIPalette.Green400}
    url('https://theme.zdassets.com/theme_assets/364245/581612e94504392660ae8643e50fb3dd91862f7e.svg')
    no-repeat top center;
  background-size: 900px;
  border-style: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  border-radius: 0;
  width: 100%;
  position: relative;
  padding: 85px 45px 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQueries.sm`
    padding-bottom: 120px;
  `}

  ${mediaQueries.md`
    padding-bottom: 112px;
  `}

  ${mediaQueries.lg`
    padding-bottom: 168px;
    padding-top: 150px;
    background-size: auto;
  `}
`

export const Container = styled.div``

export const Label = styled.h1`
  text-align: center;
  font-weight: ${fontWeight.regular};
  font-size: 2.1rem;
  line-height: ${lineHeight.large};
  margin: 0 0 7px;

  ${mediaQueries.sm`
    margin-bottom: 0px;
    font-size: ${fontSize.x4Large};
  `}
`

export const HeroInner = styled.div`
  grid-column: 1 / span 12;
  max-width: 820px;
  margin: 0 auto;
`

export const AlertPopup = styled.div`
  min-height: 18px;
  margin: 56px 0;

  ${mediaQueries.md`
    margin-bottom: 28px;
  `}
`
