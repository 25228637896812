import styled from 'styled-components'
import { GridContainer } from 'components/atoms/layout/'
import { VIDEO_HEIGHT } from 'components/help/video-link-card/video-link-card.styles.js'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

const GRID_GAP = 32
const ROW_HEIGHT = GRID_GAP + VIDEO_HEIGHT

export const SectionContainer = styled.section`
  background-color: ${NeutralColors.GreyPaper};
  padding: 40px 0 46px;
  display: flex;
  justify-content: center;
`

export const VideoWrapper = styled(GridContainer)`
  max-width: 1200px;
  padding: 0 24px 40px;
  transition: all 0.6s;
  overflow: hidden;
  max-height: ${({ open, count }) =>
    open ? `${ROW_HEIGHT * count}px` : `${ROW_HEIGHT}px`};

  /* Add additional row-gap when closed so the shadows don't show */
  row-gap: ${({ open }) => (open ? GRID_GAP : GRID_GAP + 15)}px;
`
