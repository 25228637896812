import { NAMESPACES } from 'constants/locales'

import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import {
  AlertPopup,
  Container,
  HeroInner,
  Label,
  StyledHeader,
} from './header.styles'
import Search from './search'
import { StatusBanner } from './status-banner'

export const Header = () => {
  const { t } = useTranslation(NAMESPACES.HELP)

  return (
    <StyledHeader data-testid='header' data-qa='header'>
      <Container>
        <AlertPopup id='statuspage-pop-up' />
        <StatusBanner />
        <HeroInner>
          <Label>{t('help-center.hero.headline')}</Label>
          <Search />
        </HeroInner>
      </Container>
    </StyledHeader>
  )
}

Header.propTypes = {}
