import { NAMESPACES } from 'constants/locales'

import React, { useState, useCallback, useEffect, forwardRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useDebounce } from 'react-use'
import { useRouter } from 'next/router'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { useHelpHubResults } from 'components/help/hooks'
import { persistSearchOrigin } from 'utils/help-center/session-storage'
import { shouldTrackSearchQueryInput } from 'utils/tracking'
import { routes } from 'utils/routes'

import { DEBOUNCE_INPUT_QUERY_MS } from '../constants/index'
import { helpHubTrackSearchQueryRemoved } from '../tracking'

import {
  StyledSearchWrapper,
  StyledSearchInput,
  StyledSearchBackdrop,
  StyledSearchClearIcon,
} from './search.styles'
import SearchResults from './search-results'

const Search = forwardRef(
  (
    {
      onClose,
      autoFocus,
      location,
    }: {
      onClose?: () => void
      autoFocus?: boolean
      location: string
    },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { t, lang } = useTranslation(NAMESPACES.HELP)
    const [query, setQuery] = useState('')
    const [debouncedQuery, setDebouncedQuery] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const router = useRouter()
    const [searchPending, setSearchPending] = useState(false)

    const [, cancel] = useDebounce(
      () => setDebouncedQuery(query),
      DEBOUNCE_INPUT_QUERY_MS,
      [query]
    )

    const { results, isLoading, isEmpty } = useHelpHubResults({
      query: debouncedQuery,
    })

    useEffect(() => {
      if (!isLoading) {
        setSearchPending(false)
      }
    }, [isLoading])

    const onClearClick = useCallback(() => {
      if (shouldTrackSearchQueryInput(debouncedQuery)) {
        helpHubTrackSearchQueryRemoved({
          search_query: debouncedQuery,
          location,
        })
      }
      setQuery('')
      if (ref && 'current' in ref && ref.current) {
        ref.current.focus()
      }
    }, [debouncedQuery, ref, location])

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        // If the debounced function is pending, then we will cancel it and inform the results page that the search was triggered from the main page
        if (searchPending) {
          cancel()
          persistSearchOrigin(location)
        }
        router.push({
          pathname: routes(lang).help.search,
          query: {
            q: query,
          },
        })
      }
    }

    const onInputChanged = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchPending(true)
        setQuery(e.target.value)
      },
      [setSearchPending, setQuery]
    )

    const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        setIsFocused(false)
        onClose?.()
      }
    }

    return (
      <>
        <StyledSearchBackdrop show={isFocused} onClick={handleBackdropClick} />
        <StyledSearchWrapper>
          <StyledSearchInput
            value={query}
            isFocused={isFocused}
            placeholder={
              !isFocused && ref ? t('help-center.hero.placeholder') : ''
            }
            autoComplete='off'
            aria-label={t('help-center.hero.placeholder')}
            onChange={onInputChanged}
            onFocus={() => setIsFocused(true)}
            onKeyDown={onKeyDown}
            ref={ref}
            // eslint-disable-next-line jsx-a11y/no-autofocus -- we want the input focus for users on search
            autoFocus={autoFocus}
          />
          <StyledSearchClearIcon onClick={onClearClick} show={isFocused}>
            <svg
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5.99951 4.93948L10.5078 0.431152L11.5703 1.49362L7.06197 6.00195L11.5703 10.5103L10.5078 11.5727L5.99951 7.06441L1.49117 11.5727L0.428711 10.5103L4.93704 6.00195L0.428711 1.49362L1.49117 0.431152L5.99951 4.93948Z'
                fill={NeutralColors.Grey600}
              />
            </svg>
          </StyledSearchClearIcon>
          <SearchResults
            results={results}
            open={isFocused}
            isEmpty={isEmpty}
            isLoading={isLoading}
            query={debouncedQuery}
            location={location}
          />
        </StyledSearchWrapper>
      </>
    )
  }
)
Search.displayName = 'Search'

export default Search
