import styled from 'styled-components'

const MARGIN = '8px'

export const StyledSearchResultLink = styled.li`
  margin-bottom: ${MARGIN};
`
export const StyledSearchResultLinkIcon = styled.span`
  margin-right: ${MARGIN};
`
