import PropTypes from 'prop-types'
import { useState } from 'react'
import VideoLinkCard from 'components/help/video-link-card'
import { routes } from 'utils/routes'
import { useLocaleContext } from 'components/locale-context'

import SeeMoreTutorials from '../see-more-tutorials'

import { SectionContainer, VideoWrapper } from './video-section.styles'

const VideoSection = ({ videos, id }) => {
  const { locale } = useLocaleContext()
  const [open, setOpen] = useState(false)
  return (
    <>
      <SectionContainer id={id} data-cy='help-centre-videos'>
        <VideoWrapper open={open} count={videos.length}>
          {videos.map(videoProps => (
            <VideoLinkCard
              key={videoProps.videoId}
              title={videoProps.title}
              duration={videoProps.duration}
              image={videoProps.image}
              locale={locale}
              href={routes(locale).help.article(videoProps.article)}
            />
          ))}
        </VideoWrapper>
      </SectionContainer>
      {videos.length > 3 && (
        <SeeMoreTutorials
          isActive={open}
          onClick={() => setOpen(old => !old)}
        />
      )}
    </>
  )
}

VideoSection.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      duration: PropTypes.string,
      article: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
  id: PropTypes.string,
}

VideoSection.defaultProps = {
  videos: [],
  id: null,
}

VideoSection.displayName = 'VideoLink'

export default VideoSection
