import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import {
  SOURCE_INSIDED,
  SOURCE_ZENDESK,
} from 'services/help/search/search-providers/constants'

export const POST = 'post'
export const VIDEO_TUTORIAL = 'video'
export const ARTICLE = 'article'

const VIDEO_ICON = (
  <svg
    width='16'
    height='12'
    viewBox='0 0 16 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14 0H2C0.8955 0 0 0.8955 0 2V10C0 11.1045 0.8955 12 2 12H14C15.1045 12 16 11.1045 16 10V2C16 0.8955 15.1045 0 14 0ZM11.044 6.7215L5.438 9.5V2.5L11.044 5.2785C11.641 5.574 11.641 6.4255 11.044 6.7215Z'
      fill={NeutralColors.Ink}
    />
  </svg>
)

const POST_ICON = (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14 0H0V6L7.0495 16V11H14C15.1045 11 16 10.1045 16 9V2C16 0.8955 15.1045 0 14 0ZM5 6.5C4.448 6.5 4 6.0525 4 5.5C4 4.9475 4.448 4.5 5 4.5C5.552 4.5 6 4.9475 6 5.5C6 6.0525 5.552 6.5 5 6.5ZM8.5 6.5C7.948 6.5 7.5 6.0525 7.5 5.5C7.5 4.9475 7.948 4.5 8.5 4.5C9.052 4.5 9.5 4.9475 9.5 5.5C9.5 6.0525 9.052 6.5 8.5 6.5ZM12 6.5C11.448 6.5 11 6.0525 11 5.5C11 4.9475 11.448 4.5 12 4.5C12.552 4.5 13 4.9475 13 5.5C13 6.0525 12.552 6.5 12 6.5Z'
      fill={NeutralColors.Ink}
    />
  </svg>
)

const ARTICLE_ICON = (
  <svg
    width='14'
    height='10'
    viewBox='0 0 14 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 8H14C14 9.10457 13.1046 10 12 10H8V8ZM0 8H6V10H0V8ZM5 4H14V6H5V4ZM0 4H3V6H0V4ZM10 0H14V2H10V0ZM0 0H8V2H0V0Z'
      fill={NeutralColors.Ink}
    />
  </svg>
)

const EXTERNAL_ICON = (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.44776 0C6.44776 0.7365 7.04476 1.3335 7.78126 1.3335H11.5033L4.78076 8.0555C5.36676 8.6415 6.31626 8.6415 6.90226 8.0555L12.6663 2.2915V5.886C12.6663 6.6225 13.2633 7.2195 13.9998 7.2195V0H6.44776Z'
      fill={NeutralColors.Ink}
    />
    <path
      d='M9 11.288C9 11.9535 8.4585 12.495 7.793 12.495H2.707C2.0415 12.495 1.5 11.9535 1.5 11.288V6.202C1.5 5.5365 2.0415 4.995 2.707 4.995H5.1315L6.6315 3.495H2.707C1.212 3.495 0 4.707 0 6.202V11.288C0 12.783 1.212 13.995 2.707 13.995H7.793C9.288 13.995 10.5 12.783 10.5 11.288V7.405L9 8.90499V11.288Z'
      fill={NeutralColors.Ink}
    />
  </svg>
)

export const CARD_ICONS = {
  [ARTICLE]: ARTICLE_ICON,
  [VIDEO_TUTORIAL]: VIDEO_ICON,
  [POST]: POST_ICON,
}

export const RESULT_ICONS = {
  [ARTICLE]: ARTICLE_ICON,
  [VIDEO_TUTORIAL]: VIDEO_ICON,
  [POST]: EXTERNAL_ICON,
}

export const SOURCES_KEYS = {
  [SOURCE_ZENDESK]: 'help-hub.sources.zendesk',
  [SOURCE_INSIDED]: 'help-hub.sources.insided',
}

export const DEBOUNCE_INPUT_QUERY_MS = 500
export const QUERY_MIN_LENGTH = 4
export const DEFAULT_RESULT_DATA = {
  count: 0,
  page: undefined,
  results: [],
  size: 0,
  nextPage: undefined,
}

export const HELP_HUB_PATH = '/help/'
export const HELP_HUB_SEARCH_PATH = '/help/search/'
