import React from 'react'
import PropTypes from 'prop-types'

import ContactSupport from './contact-support'
import CommunityBanner from './community-banner'

const ClosingSection = ({ showCommunityBanner, showContactSupport }) => (
  <>
    {showCommunityBanner && <CommunityBanner />}
    {showContactSupport && <ContactSupport />}
  </>
)

ClosingSection.propTypes = {
  showCommunityBanner: PropTypes.bool,
  showContactSupport: PropTypes.bool,
}

ClosingSection.defaultProps = {
  showCommunityBanner: true,
  showContactSupport: true,
}

export default ClosingSection
