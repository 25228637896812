import styled from 'styled-components'
import { UIPalette } from '@typeform/ginger/dist/constants/colors'

export const Wrapper = styled.section`
  background: ${UIPalette.Green200};
  margin: 0;
  width: 100%;
  position: relative;
  z-index: 20;
  padding: 30px 24px;
  height: 318px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  button {
    min-width: 170px;
  }
`
