import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import { useLocaleContext } from 'components/locale-context'
import PropTypes from 'prop-types'
import React, { useRef, useEffect } from 'react'

import SuggestionIcon from './suggestion-icon'
import SuggestionSnippet from './suggestion-snippet'
import { StyledLink, StyledTitle } from './suggestion.styles'

const Suggestion = ({ data, isActive }) => {
  const linkRef = useRef()
  const { locale } = useLocaleContext()
  const hrefLocale = locale === LANGUAGE_CODES.english ? '' : `/${locale}`
  const href = `${hrefLocale}${data.url}`

  useEffect(() => {
    if (isActive && linkRef.current) {
      linkRef.current.focus()
    }
  }, [isActive])

  return (
    <StyledLink href={href} ref={linkRef}>
      <StyledTitle>{data.title}</StyledTitle>
      <SuggestionIcon suggestion={data} />
      <SuggestionSnippet html={data.snippet} />
    </StyledLink>
  )
}

Suggestion.propTypes = {
  isActive: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    duration: PropTypes.string,
    snippet: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
}

export default Suggestion
