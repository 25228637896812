import GridContainer from 'components/atoms/grid-container'
import { Headline } from '@typeform/ginger/dist/components/typography/headline'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

export const XS_SECTION_VERTICAL_SPACING = '40px'
export const SM_SECTION_VERTICAL_SPACING = ({ standalone }) =>
  standalone ? '50px' : '64px'
export const MD_SECTION_VERTICAL_SPACING = ({ standalone }) =>
  standalone ? '50px' : '96px'

export const StyledSectionHeadline = styled(Headline)`
  margin: 0;
  padding-top: ${({ standalone }) => (standalone ? 40 : 80)}px;
  text-align: ${({ standalone }) => (standalone ? 'left' : 'center')};
  text-transform: lowercase;
  ::first-letter {
    text-transform: uppercase;
  }
`

export const StyledSection = styled.section`
  background-color: ${NeutralColors.GreyPaper};
`

export const StyledGridContainer = styled(GridContainer)`
  padding: ${XS_SECTION_VERTICAL_SPACING} 0 0 0;
  ${mediaqueries.sm`
    padding: ${SM_SECTION_VERTICAL_SPACING} 0 0 0;
  `}
  ${mediaqueries.md`
    padding: ${MD_SECTION_VERTICAL_SPACING} 0 0 0;
  `}
`
