import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import Search from 'components/help/hub/header/search'
import { trackItemClicked } from 'components/tracking'

import Section from './section/section'
import {
  BackgroundDropContainer,
  SearchContainer,
  SearchWrapper,
  StyledNav,
  StyledSections,
  StyledHCSearchButton,
  StyledSearchIcon,
} from './vertical-menu.styles'

const VerticalMenu = ({ sections, currentSectionId }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const searchInputRef = useRef(null)

  const handleSearchOpenTracking = useCallback(() => {
    trackItemClicked({
      item: 'open_search_bar',
      item_type: 'button',
      location: 'vertical_side_help_center',
    })
  }, [])

  const handleCloseSearchTracking = useCallback(() => {
    trackItemClicked({
      item: 'close_search_bar',
      item_type: 'background',
      location: 'vertical_side_help_center',
    })
  }, [])

  const handleSearchOpen = useCallback(() => {
    setIsSearchOpen(true)
    handleSearchOpenTracking()
    setTimeout(() => {
      searchInputRef.current?.focus()
    }, 0)
  }, [handleSearchOpenTracking])

  const handleSearchClose = useCallback(() => {
    setIsSearchOpen(false)
    handleCloseSearchTracking()
  }, [handleCloseSearchTracking])

  return (
    <StyledNav>
      <StyledHCSearchButton
        onClick={handleSearchOpen}
        palette='positive'
        variant='secondary'
      >
        <StyledSearchIcon /> Search
      </StyledHCSearchButton>
      <StyledSections>
        {sections.map(section => (
          <Section
            key={section.id}
            section={section}
            isActive={currentSectionId === section.id}
          />
        ))}
      </StyledSections>
      {isSearchOpen && (
        <BackgroundDropContainer
          isOpen={isSearchOpen}
          onClick={e => e.target === e.currentTarget && handleSearchClose()}
        >
          <SearchWrapper>
            <SearchContainer onClick={e => e.stopPropagation()}>
              <Search
                ref={searchInputRef}
                onClose={handleSearchClose}
                location='vertical_side_hc_menu'
                // eslint-disable-next-line jsx-a11y/no-autofocus -- we need to autofocus the search input
                autoFocus
              />
            </SearchContainer>
          </SearchWrapper>
        </BackgroundDropContainer>
      )}
    </StyledNav>
  )
}

VerticalMenu.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  currentSectionId: PropTypes.number,
}
VerticalMenu.defaultProps = {
  currentSectionId: null,
}

export default VerticalMenu
