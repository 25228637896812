import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { css } from 'styled-components'

// Values from: https://www.figma.com/file/IMJFtgCZACksKTzjElo5Zq/HC-Refinements

export const content = {
  sizeL: css`
    font-size: ${fontSize.large};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.small};
  `,
  sizeM: css`
    font-size: ${fontSize.medium};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.medium};
  `,
  sizeS: css`
    font-size: ${fontSize.small};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.large};
  `,
  sizeXS: css`
    font-size: ${fontSize.xSmall};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.large};
  `,
  sizeXXS: css`
    font-size: ${fontSize.x2Small};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.large};
  `,
}

export const headline = {
  size5: css`
    font-size: ${fontSize.x5Large};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.xSmall};
  `,
  size4: css`
    font-size: ${fontSize.x4Large};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.xSmall};
  `,
  size3: css`
    font-size: ${fontSize.x3Large};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.xSmall};
  `,
  size2: css`
    font-size: ${fontSize.x2Large};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.xSmall};
  `,
  size1: css`
    font-size: ${fontSize.xLarge};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.small};
  `,
  size0: css`
    font-size: ${fontSize.large};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.small};
  `,
}
