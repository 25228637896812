import PropTypes from 'prop-types'
import React from 'react'

import { StyledRenderHtml } from './suggestion-snippet.styles'

const SuggestionSnippet = ({ html }) => {
  if (!html) {
    return null
  }

  return <StyledRenderHtml dangerouslySetInnerHTML={{ __html: html }} />
}

SuggestionSnippet.propTypes = {
  html: PropTypes.string,
}

SuggestionSnippet.defaultProps = {
  html: null,
}

export default SuggestionSnippet
