import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'

export const IconContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  align-items: center;
  grid-area: suggestionIcon;
  border: 1px solid ${NeutralColors.Ink};
  border-radius: ${BORDER_RADIUS.large};
  padding: 0 12px;
`

export const IconText = styled.div`
  color: ${NeutralColors.Ink};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
`

export const IconSvg = styled.div`
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 12px;
  width: 32px;

  svg {
    height: 1em;
  }
`
