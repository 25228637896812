import { useCallback, useRef } from 'react'

/**
 * Caches a value. Defers updating the value until a
 * non-equal, non-undefined next value is specified.
 * @param {*} initialValue
 * @returns The cached value.
 */
const useDeferredValue = initialValue => {
  const deferredValueRef = useRef(initialValue)

  return useCallback(nextValue => {
    if (nextValue !== undefined && deferredValueRef.current !== nextValue) {
      deferredValueRef.current = nextValue
    }
    return deferredValueRef.current
  }, [])
}

export default useDeferredValue
