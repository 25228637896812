import VerticalCaret from 'components/atoms/common/vertical-caret'
import GridContainer from 'components/atoms/grid-container'
import {
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from '@typeform/ginger/dist/lib/constants'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import styled from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'

const ADDITIONAL_TOP_PADDING = 16

export const StyledSection = styled(GridContainer)`
  background-color: ${NeutralColors.GreyPaper};
  grid-template-areas:
    'article article article article'
    'aside aside aside aside';
  margin: 0 auto;
  padding-top: ${ADDITIONAL_TOP_PADDING + MOBILE_HEADER_HEIGHT}px;

  ${mediaQueries.sm`
    grid-template-areas: 'aside aside aside - article article article article article article article article';
  `}

  ${mediaQueries.md`
    padding-top: ${ADDITIONAL_TOP_PADDING + HEADER_HEIGHT}px;
  `}
`

export const StyledArticle = styled.article`
  grid-area: article;
  padding-bottom: 64px;
  max-width: 100%;
  min-height: 460px;
  overflow-x: hidden;

  > * {
    max-width: 100%;
  }

  ${mediaQueries.md`
    padding-bottom: 96px;
  `}
`

export const StyledAside = styled.aside`
  grid-area: aside;
`

export const TextButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  text-transform: uppercase;
  color: ${NeutralColors.Ink};
`

export const StyledCategoryTitle = styled.h3`
  display: block;

  ${mediaQueries.sm`
    display: none;
  `}
`

export const StyledCategoryContainer = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};

  ${mediaQueries.sm`
    display: block;
  `}
`

export const StyledVerticalCaret = styled(VerticalCaret)`
  margin-left: 8px;
  vertical-align: middle;
`
