import { Headline } from '@typeform/ginger/dist/components/typography'
import { fontWeight } from '@typeform/ginger/dist/constants/typography'
import styled from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import { content, headline } from '../layout/base.styles'

export const StyledHeadline = styled(Headline)`
  margin-top: 64px;
  margin-bottom: 24px;

  ${headline.size2};
`

export const StyledContent = styled.div`
  * {
    max-width: 100%;
    overflow-x: auto;
  }

  max-width: 100%;

  ${content.sizeM};

  h3 {
    margin-top: 40px;
    margin-bottom: 24px;

    ${headline.size1};
  }

  p {
    margin-top: 0;
    margin-bottom: 24px;
  }

  a {
    font-weight: ${fontWeight.medium};
    text-decoration: underline;
  }

  strong {
    font-weight: ${fontWeight.medium};
  }

  img {
    border-radius: 9px;
    max-width: 100%;
  }

  .tipsnotes {
    background: ${NeutralColors.White};
    border-radius: ${BORDER_RADIUS.large};
    padding: 40px 64px 56px;
    margin: 64px 0;

    ${content.sizeM};
  }

  img.imgtips {
    width: auto;
    max-height: 100px;
    margin-bottom: 22px;
  }

  /* targeting article images but not tips notes */

  p > img {
    box-shadow: 0 4px 15px rgb(0 0 0 / 15%);
    display: flex;
    height: auto;
    margin: 64px 0;
    max-width: 90%;

    ${mediaQueries.md`
      height: auto;
      max-width: 100%;
    `}
  }
`
