import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import Script from 'next/script'
export const StatusWidget = () => {
  return (
    <>
      <Script
        src={`https://status.${TYPEFORM_ROOT_DOMAIN}/embed/script.js`}
        strategy='lazyOnload'
      />
    </>
  )
}
