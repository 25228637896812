import { NAMESPACES } from 'constants/locales'
import { WISTIA_EMBED_URL } from 'constants/url'

import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import useTranslation from 'next-translate/useTranslation'
import { Picture } from 'components/atoms/images'
import { GridContainer } from 'components/atoms/layout/'
import { ButtonVariant } from 'components/atoms/button'
import { Icon } from 'components/modified-kitt/icon'
import { cross, video } from 'components/modified-kitt/icons'
import React, { useState } from 'react'
import { Palette } from '@typeform/ginger/dist/constants/palettes'

import Button from '../button'

import {
  CloseOverlayButton,
  StyledGingerSection,
  StyledHeading,
  StyledIframe,
  StyledPictureContainer,
  StyledTextContainer,
  VideoOverlay,
} from './highlighted-video-section.styles'

const imagePath =
  'https://images.ctfassets.net/co0pvta7hzrh/5agBxuOfseLwCeUiD2PJsc/f72de3974754b19b3d813ee8e3bd268a/banner.png'

const srcSet = {
  srcSetWebP: `${imagePath}?fm=webp`,
  srcSet: imagePath,
  original: imagePath,
}

export const HighlightedVideoSection = () => {
  const [isOverlayShown, setIsOverlayShown] = useState(false)
  const { t } = useTranslation(NAMESPACES.HELP)

  return (
    <StyledGingerSection>
      <GridContainer style={{ alignItems: 'end' }}>
        {isOverlayShown && (
          <VideoOverlay>
            <CloseOverlayButton
              variant={ButtonVariant.Secondary}
              onClick={() => setIsOverlayShown(false)}
            >
              <Icon svg={cross} boundarySize={6} />
            </CloseOverlayButton>
            <StyledIframe
              allowFullScreen
              title='wistia iframe'
              allowtransparency='true'
              scrolling='no'
              name='wistia_embed'
              src={`${WISTIA_EMBED_URL}/nmtwrun5kq?autoPlay=true`}
            />
          </VideoOverlay>
        )}
        <StyledTextContainer>
          <StyledHeading>
            {t('help-center.highlighted-video.header')}
          </StyledHeading>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={() => setIsOverlayShown(true)}
            palette={Palette.Negative}
            data-gtm-event='cta_button'
          >
            <Icon color={NeutralColors.White} svg={video} />
            {t('help-center.highlighted-video.watch-button')}
          </Button>
        </StyledTextContainer>
        <StyledPictureContainer>
          <Picture
            src={srcSet.original}
            alt={t('help-center.highlighted-video.header')}
            srcset={srcSet}
            loading='lazy'
          />
        </StyledPictureContainer>
      </GridContainer>
    </StyledGingerSection>
  )
}
