import styled, { css } from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'

import { FONT_WEIGHT_BOLD } from '../typography.styles'

import { ResultTextStyles } from './search-results.styles'

export const CategoryTitle = styled.h6`
  justify-content: space-between;
  margin-bottom: 24px;

  ${ResultTextStyles};
`

const TextStyle = css`
  font-weight: ${FONT_WEIGHT_BOLD};
  font-size: ${fontSize.xSmall};
  line-height: ${lineHeight.large};
`

export const CategoryMore = styled.span`
  display: flex;

  ${TextStyle};

  div {
    margin-left: 10.29px;
  }
`
export const GroupList = styled.ol`
  padding: 0 0 0 16px;
  margin: 0 0 10px;
  list-style: none;
  ${TextStyle};

  ${mediaQueries.md`
    margin: 0 0 24px 24px;
  `}
`
