import { NAMESPACES } from 'constants/locales'

import useTranslation from 'next-translate/useTranslation'

const useFieldValidation = field => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const { t: zendeskTranslate } = useTranslation(
    NAMESPACES.CONTACT_FORM_ZENDESK
  )

  return (value, matchingCondition) => {
    if (
      !value &&
      (field.required_in_portal || matchingCondition?.is_required)
    ) {
      return t('help-center.contact-form.field-error.required', {
        field: zendeskTranslate(field.raw_title_in_portal.trim()),
      })
    }
    if (
      value &&
      field.regexp_for_validation &&
      !new RegExp(field.regexp_for_validation).test(value)
    ) {
      return t('help-center.contact-form.field-error.invalid-format', {
        field: zendeskTranslate(field.raw_title_in_portal.trim()),
      })
    }

    return null
  }
}

export default useFieldValidation
