import { NAMESPACES } from 'constants/locales'

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { routes } from 'utils/routes'
import { useLocaleContext } from 'components/locale-context'
import useTranslation from 'next-translate/useTranslation'
import Collapse from 'components/atoms/common/collapse'

import {
  StyledSection,
  StyledList,
  StyledToggle,
  StyledHeadline,
  StyledArticleList,
} from './article-links-list.styles'
import Article from './article'

export const ArticleLinksList = ({ section }) => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const { locale } = useLocaleContext()
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])
  const hiddenArticles = [...section.articles]
  const visibleArticles = hiddenArticles.splice(0, 2)

  const sectionUrl = routes(locale).help.section(section)
  return (
    <StyledSection>
      <StyledHeadline size={1}>
        <a href={sectionUrl}>{section.name}</a>
      </StyledHeadline>
      <StyledList>
        {visibleArticles.map(article => {
          return (
            <Article key={article.id} article={article} section={section} />
          )
        })}
      </StyledList>
      <Collapse isOpened={isOpen}>
        <StyledArticleList>
          {hiddenArticles.map(article => (
            <Article key={article.id} article={article} section={section} />
          ))}
        </StyledArticleList>
      </Collapse>
      {hiddenArticles.length > 0 && (
        <StyledToggle as='button' onClick={handleClick}>
          {isOpen
            ? t('help-center.articles.view-less')
            : t('help-center.articles.see-all')}
        </StyledToggle>
      )}
    </StyledSection>
  )
}

ArticleLinksList.propTypes = {
  section: PropTypes.shape({
    name: PropTypes.string,
    articles: PropTypes.array,
  }).isRequired,
  headerSize: PropTypes.number,
}

ArticleLinksList.defaultProps = {
  headerSize: undefined,
}
