import useSWR from 'swr'

import useDeferredValue from './use-deferred-value'

/**
 * @typedef UseCachedSWRParams
 * @property {object} options https://swr.vercel.app/docs/options#options
 * @property {Function} fetcher https://swr.vercel.app/docs/data-fetching
 * @property {string | Array} key
 * @property {*} initialValue
 *
 * Performs a fetch and caches and returns the results. Previously
 * cached results will be returned if the fetch operation results in an
 * undefined data set.
 * @param {UseCachedSWRParams} params
 * @returns The fetched data or previously fetched and cached data.
 */
const useCachedSWR = ({ initialValue, key, fetcher, options }) => {
  const result = useSWR(key, fetcher, options)
  const getCachedValue = useDeferredValue(initialValue)
  return {
    ...result,
    data: getCachedValue(result.data),
  }
}

export default useCachedSWR
