import { useState, useEffect } from 'react'

const useDragStatus = ref => {
  const [dragStatus, setDragStatus] = useState('')

  const trackDragOverStatus = e => {
    setDragStatus(e.type)
  }

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const dragRef = ref.current
    dragRef.addEventListener('dragover', trackDragOverStatus)
    dragRef.addEventListener('dragend', trackDragOverStatus)
    dragRef.addEventListener('drop', trackDragOverStatus)
    dragRef.addEventListener('dragleave', trackDragOverStatus)

    return () => {
      dragRef.removeEventListener('dragover', trackDragOverStatus)
      dragRef.removeEventListener('dragend', trackDragOverStatus)
      dragRef.removeEventListener('drop', trackDragOverStatus)
      dragRef.removeEventListener('dragleave', trackDragOverStatus)
    }
  }, [ref])

  return dragStatus
}

export default useDragStatus
