import { NAMESPACES } from 'constants/locales'

import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'

const useFieldSelectOptions = ({ custom_field_options }) => {
  const { t } = useTranslation(NAMESPACES.CONTACT_FORM_ZENDESK)

  return useMemo(() => {
    if (!custom_field_options) {
      return []
    }

    return custom_field_options.map(customOption => {
      return {
        default: customOption.default,
        label: t(customOption.raw_name.trim()),
        value: customOption.value,
      }
    })
  }, [custom_field_options, t])
}

export default useFieldSelectOptions
