import { useMemo } from 'react'

/**
 * @typedef CustomFieldOptions
 * @property {boolean} default
 * @property {string} raw_name
 * @property {boolean|number|string} value
 *
 * Determines the initial value
 *   if value provided that matches an option then return the option value
 *   if there is an option with default: true then return the first default option's value
 *   return empty string
 *
 * @param {CustomFieldOptions} options
 * @param {boolean|number|string} value
 * @returns {boolean|number|string} selected option value, first default value
 */
const taggerToDefaultValue = (options, value) => {
  // determine if the existing value matches an option value
  const selectOption = options.find(option => option.value === value)
  if (selectOption) {
    return selectOption.value
  }

  // return first default option value
  const defaultOption = options.find(option => option.default)
  if (defaultOption) {
    return defaultOption.value
  }

  return ''
}

const useFormInitialValues = (fields, defaultValues = {}) => {
  return useMemo(() => {
    return fields.reduce((initialValues, field) => {
      if (field.type === 'tagger') {
        initialValues[field.id] = taggerToDefaultValue(
          field.custom_field_options,
          initialValues[field.id]
        )
      } else {
        initialValues[field.id] = ''
      }

      return initialValues
    }, defaultValues)
  }, [defaultValues, fields])
}

export default useFormInitialValues
