import PropTypes from 'prop-types'
import React from 'react'

import ArticleLinksList from './article-links-list'
import {
  StyledGridContainer,
  StyledSection,
  StyledSectionHeadline,
} from './links-section.styles'

export const LinksSection = ({ sections, standalone, id, title }) => {
  return (
    <StyledSection id={id} data-cy='help-centre-links'>
      <StyledSectionHeadline as='h1' size={2} standalone={standalone}>
        {title}
      </StyledSectionHeadline>
      <StyledGridContainer standalone={standalone}>
        {sections.map(section => {
          return <ArticleLinksList key={section.id} section={section} />
        })}
      </StyledGridContainer>
    </StyledSection>
  )
}

LinksSection.defaultProps = {
  headerSize: undefined,
  id: null,
  standalone: false,
}

LinksSection.propTypes = {
  headerSize: PropTypes.number,
  id: PropTypes.string,
  sections: PropTypes.array.isRequired,
  standalone: PropTypes.bool,
  title: PropTypes.string.isRequired,
}
