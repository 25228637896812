import React from 'react'

export const CloseIcon = () => {
  return (
    <svg
      width='9'
      height='9'
      viewBox='0 0 9 9'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.43412 8.43418c-.36388.36388-.95384.36388-1.31772 0L.979941 2.29773c-.363879-.36388-.363879-.95385 0-1.317728.363879-.363879.953849-.36388 1.317729 0L8.43412 7.11646c.36388.36388.36388.95384 0 1.31772z'
      ></path>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M.979941 8.43418c-.363879-.36388-.36388-.95384 0-1.31772L7.1164.980002c.36388-.363879.95384-.363879 1.31772 0 .36388.363878.36388.953848 0 1.317728L2.29767 8.43418c-.36388.36388-.95385.36388-1.317729 0z'
      ></path>
    </svg>
  )
}
