import React from 'react'

type TWatchIcon = {
  title?: string
  width?: number
  height?: number
  className?: string
}

const WatchIcon = ({
  title,
  width = 18,
  height = 9,
  className,
}: TWatchIcon) => {
  return (
    <span className={className}>
      <svg
        width={width}
        height={height}
        aria-label={title}
        fill='none'
        role='graphics-symbol'
        xmlns='http://www.w3.org/2000/svg'
      >
        <title>{title}</title>
        <rect width='11.25' height='9' rx='2' fill='#C4C4C4' />
        <path
          d='M12.2794 5.29241C11.7594 4.8921 11.7594 4.1079 12.2794 3.70759L14.7025 1.84226C15.3601 1.33606 16.3125 1.80483 16.3125 2.63467L16.3125 6.36534C16.3125 7.19518 15.3601 7.66394 14.7025 7.15774L12.2794 5.29241Z'
          fill='#C4C4C4'
        />
      </svg>
    </span>
  )
}

WatchIcon.displayName = 'WatchIcon'

export default WatchIcon
