import { NAMESPACES } from 'constants/locales'

import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components/modified-kitt/icon'
import { search } from 'components/modified-kitt/icons'
import useTranslation from 'next-translate/useTranslation'

import { StyledContainer, StyledInput } from './search-filter.styles'

export const SearchFilter = props => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const { onChange, value } = props
  return (
    <StyledContainer>
      <StyledInput
        onChange={onChange}
        placeholder={t('help-center.hero.placeholder')}
        value={value}
      />
      <Icon svg={search} />
    </StyledContainer>
  )
}

SearchFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

SearchFilter.defaultProps = {
  value: '',
}
