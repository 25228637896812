import { snakeCase } from '@s-libs/micro-dash'
import {
  trackSearchQueryEntered,
  trackSearchQueryRemoved,
  trackItemClicked,
} from 'components/tracking/utils'

const SECTION_LOCATION_OPTIONS = Object.freeze({
  TRENDING_TOPICS: 'trending_topics',
  TOP_GUIDES: 'top_guides',
  TOP_VIDEOS: 'top_videos',
  TOP_POSTS: 'top_posts',
  HELP_GUIDES: 'help_guides',
})

const TRACKING_ITEMS = {
  REDIRECT: 'redirect_to_page',
  COMMUNITY: 'community',
  CONTACT: 'contact_support',
  LOGIN: 'login',
  FILTER_CATEGORIES: 'filter_categories',
  SEARCH: 'search_result',
}

export const help_hub = 'help_hub'
const button = 'button'
const link = 'link'

export const helpHubSearchQueryEntered = ({
  search_query,
  number_of_results,
  location,
}) => {
  trackSearchQueryEntered({
    search_query,
    number_of_results,
    location,
  })
}
export const helpHubSearchQueryEnteredOnResultsPage = ({
  search_query,
  number_of_results,
  location,
}) => {
  trackSearchQueryEntered({
    search_query,
    number_of_results,
    location: location || `${help_hub}:results_page`,
  })
}

export const helpHubTrackSearchQueryRemoved = ({ search_query, location }) => {
  if (!search_query) {
    return
  }
  trackSearchQueryRemoved({
    search_query,
    location,
  })
}

export const helpHubTrackClickSearchResult = ({
  search_result_clicked,
  label,
}) => {
  trackItemClicked({
    item: TRACKING_ITEMS.SEARCH,
    item_type: button,
    search_result_clicked,
    label,
  })
}

export const helpHubTrackChangeSearchFilter = ({ label }) => {
  trackItemClicked({
    item: TRACKING_ITEMS.FILTER_CATEGORIES,
    item_type: 'checkbox',
    label: snakeCase(label),
  })
}

export const helpHubTrackClickSearchResultPageItem = ({ link_url, label }) => {
  trackItemClicked({
    item: TRACKING_ITEMS.REDIRECT,
    item_type: button,
    link_url,
    label: snakeCase(label),
  })
}

export const helpHubTrackClickSectionButton = (tracking = {}) => {
  const { location, label, typeform_property } = tracking
  const locationOptions = Object.values(SECTION_LOCATION_OPTIONS)
  if (location && !locationOptions.includes(location)) {
    throw new Error(`location must be one of: ${locationOptions.join(', ')}`)
  }
  const payload = {
    ...tracking,
    typeform_property: typeform_property || help_hub,
    label: snakeCase(label),
  }
  if (location) {
    payload.location = location
  }
  trackItemClicked(payload)
}

const createHelpHubTrackClickLink =
  item =>
  ({ link_url, label }) => {
    trackItemClicked({
      item,
      item_type: link,
      link_url,
      label: snakeCase(label),
    })
  }

export const helpHubTrackClickCommunityLink = createHelpHubTrackClickLink(
  TRACKING_ITEMS.COMMUNITY
)
export const helpHubTrackClickContactSupportLink = createHelpHubTrackClickLink(
  TRACKING_ITEMS.CONTACT
)
export const helpHubTrackClickTooltipLogin = createHelpHubTrackClickLink(
  TRACKING_ITEMS.LOGIN
)
