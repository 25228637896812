import PropTypes from 'prop-types'
import { useMemo, useCallback } from 'react'

import { RESULT_PROP_TYPES } from '../constants/prop-types'
import { RESULT_ICONS, POST } from '../constants'
import { helpHubTrackClickSearchResult } from '../tracking'

import {
  StyledSearchResultLink,
  StyledSearchResultLinkIcon,
} from './search-result-link.styles'

const SearchResultLink = ({ result, highlightRegex, query }) => {
  const title = useMemo(() => {
    if (!highlightRegex) {
      return result.title
    }
    return `${result.title}`.replace(
      highlightRegex,
      match => `<strong>${match}</strong>`
    )
  }, [result, highlightRegex])

  const handleSearchResultClick = useCallback(() => {
    helpHubTrackClickSearchResult({
      search_result_clicked: result?.title,
      label: query,
    })
  }, [result, query])

  return (
    <StyledSearchResultLink>
      <a
        onClick={handleSearchResultClick}
        href={result.url}
        target={result.type === POST ? '_blank' : undefined}
        rel='noreferrer'
      >
        <StyledSearchResultLinkIcon>
          {RESULT_ICONS[result.type]}
        </StyledSearchResultLinkIcon>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </a>
    </StyledSearchResultLink>
  )
}

SearchResultLink.defaultProps = {
  highlightRegex: null,
  query: '',
}

SearchResultLink.propTypes = {
  result: RESULT_PROP_TYPES.isRequired,
  highlightRegex: PropTypes.object,
  query: PropTypes.string,
}

export default SearchResultLink
