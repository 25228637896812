import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import { BOX_SHADOWS } from '@typeform/ginger/dist/constants/shadows'
import {
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import styled, { css } from 'styled-components'

import { FONT_WEIGHT_NORMAL, BoldBodyCss } from '../typography.styles.js'

export const BACKDROP_ZINDEX = 102
const SEARCH_INPUT_ZINDEX = BACKDROP_ZINDEX + 2
export const SEARCH_INPUT_HEIGHT = 56

export const SearchSurfaceStyles = css`
  background-color: ${NeutralColors.White};
  box-shadow: ${BOX_SHADOWS.small};
  border-radius: ${BORDER_RADIUS.small};
  border-width: 0;
`

export const StyledSearchWrapper = styled.div`
  z-index: ${BACKDROP_ZINDEX + 1};
  position: relative;
`

const SearchInputFocusProps = css`
  outline: none;
  padding: 16px 32px;
  ${BoldBodyCss};
  ${SearchSurfaceStyles};
`

export const StyledSearchInput = styled.input<{
  ref?: React.ForwardedRef<HTMLInputElement>
  isFocused: boolean
}>`
  width: 100%;
  font-weight: ${FONT_WEIGHT_NORMAL};
  font-size: ${fontSize.large};
  line-height: ${lineHeight.small};
  border-width: 0 0 1px;
  height: ${SEARCH_INPUT_HEIGHT}px;
  font-family: inherit;
  z-index: ${SEARCH_INPUT_ZINDEX};
  border-bottom-color: ${NeutralColors.Grey700};
  border-bottom-style: solid;
  border-radius: 0;
  position: relative;
  background-color: ${NeutralColors.White};

  ${({ isFocused }) => isFocused && SearchInputFocusProps}
  &::placeholder {
    color: ${NeutralColors.Ink};
  }

  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
`

export const StyledSearchClearIcon = styled.span`
  position: absolute;
  top: 22px;
  right: 44px;
  z-index: ${SEARCH_INPUT_ZINDEX + 1};
  display: ${({ show }: { show: boolean }) => (show ? 'initial' : 'none')};
  cursor: pointer;
`

export const StyledSearchBackdrop = styled.div`
  /* stylelint-disable-next-line ginger/no-hardcoded-colors */
  background: rgba(38, 38, 39, 0.8);
  opacity: 0.2;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: ${({ show }: { show: boolean }) => (show ? 'initial' : 'none')};
  z-index: ${BACKDROP_ZINDEX};
`
