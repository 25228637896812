export const SOURCE_ZENDESK = 'zendesk'
export const SOURCE_INSIDED = 'insided'

export const ZENDESK_FILTERS = {
  ARTICLES: 'hc-articles',
  VIDEOS: 'hc-videos',
}

export const INSIDED_FILTERS = {
  COMMUNITY: 'community',
}
