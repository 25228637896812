import { NAMESPACES } from 'constants/locales'
import { STATUS_BASE_URL } from 'constants/url'

import { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useMountedState } from 'react-use'
import { trackClickEvent } from 'components/tracking'
import { UIPalette } from '@typeform/ginger/dist/constants/colors'

import {
  PopupWrapper,
  StyledContent,
  StyledCta,
  StyledIconWrapper,
} from './status-banner.styles'

export const StatusBanner = () => {
  const isMounted = useMountedState()
  const { t } = useTranslation(NAMESPACES.HELP)
  const [hasError, setHasError] = useState(false)
  useEffect(() => {
    async function fetchStatus() {
      try {
        const response = await fetch(`${STATUS_BASE_URL}/api/v2/status.json`)
        const status = await response.json()
        const indicator = status?.status?.indicator
        // there is an error if the indicator is not equal to "none"
        if (isMounted()) {
          setHasError(indicator !== 'none')
        }
      } catch (error) {}
    }
    fetchStatus()
  }, [isMounted])

  if (!hasError) {
    return null
  }

  const handleClick = () => {
    trackClickEvent({
      item: 'typeform_system_status',
      item_type: 'link',
    })
  }

  return (
    <PopupWrapper>
      <div data-testid='status-banner'>
        <StyledIconWrapper>
          <svg
            width='32'
            height='29'
            viewBox='0 0 32 29'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M31.506 23.2064L19.1613 1.82472C17.7563 -0.608241 14.2443 -0.608241 12.8394 1.82472L0.494522 23.2064C-0.910457 25.6394 0.845517 28.6814 3.65548 28.6814H28.344C31.154 28.6814 32.91 25.6394 31.506 23.2064ZM16.0003 24.6814C14.8953 24.6814 14.0003 23.7864 14.0003 22.6814C14.0003 21.5764 14.8953 20.6814 16.0003 20.6814C17.1053 20.6814 18.0003 21.5764 18.0003 22.6814C18.0003 23.7854 17.1053 24.6814 16.0003 24.6814ZM18.0003 15.6815C18.0003 16.7815 17.1003 17.6815 16.0003 17.6815C14.9003 17.6815 14.0003 16.7815 14.0003 15.6815V9.68161C14.0003 8.58163 14.9003 7.68165 16.0003 7.68165C17.1003 7.68165 18.0003 8.58163 18.0003 9.68161V15.6815Z'
              fill={UIPalette.Orange400}
            />
          </svg>
        </StyledIconWrapper>
        <div>
          <h3>{t('help-center.status-banner.title')}</h3>
          <StyledContent>
            {t('help-center.status-banner.body')}
            &nbsp;
            <StyledCta
              href={`${STATUS_BASE_URL}/`}
              target='_blank'
              rel='noopener noreferrer'
              onClick={handleClick}
              data-testid='status-banner-cta'
            >
              {t('help-center.status-banner.cta-text')}
            </StyledCta>
          </StyledContent>
        </div>
      </div>
    </PopupWrapper>
  )
}
