import { fontWeight } from '@typeform/ginger/dist/constants/typography'
import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import { content } from '../../layout/base.styles'

export const StyledSection = styled.li`
  padding: 20px 0;
  border-bottom: 1px solid ${NeutralColors.Grey300};
  position: relative;
`

export const StyledArticles = styled.ul`
  margin: 0;
  padding: 0;
`

export const StyledArticle = styled.li`
  padding: 15px 0 0 20px;
  ${content.sizeXS};

  a {
    display: block;

    &:hover {
      font-weight: ${fontWeight.medium};
    }
  }
`

export const SectionHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

export const SectionHeading = styled.h3`
  flex-grow: 1;
  margin: 0 10px 0 0;
  font-weight: ${({ isActive }) => {
    return isActive ? 'bold' : 'inherit'
  }};

  ${content.sizeS};

  &:hover {
    font-weight: ${fontWeight.medium};
  }
`

export const ArticleCount = styled.span`
  color: ${NeutralColors.Grey300};

  ${content.sizeXXS};
`
