import {
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import WatchIcon from 'components/atoms/common/watch-icon'

export const VIDEO_HEIGHT = 220

export const Card = styled.a`
  display: block;
  position: relative;
  height: ${VIDEO_HEIGHT}px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgb(0 0 0 / 15%);
  transition: 0.5s all;

  :hover {
    box-shadow: 0 15px 25px rgb(0 0 0 / 25%);
  }

  grid-column: span 12;

  ${mediaQueries.sm`
    grid-column: span 6;
  `};

  ${mediaQueries.md`
    grid-column: span 4;
  `};
`

export const ImageContainer = styled.div`
  height: 100%;

  /* Setting a background color otherwise, before the image loads, we're showing white text on white background */
  background-color: ${NeutralColors.Grey600};
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  /* This targets the div picture wrapper */
  & > div {
    height: 100%;
  }
`

export const InfoContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 30px 30px 0;
`

export const Title = styled.h3`
  color: ${NeutralColors.White};
  font-size: 32px;
  font-weight: ${fontWeight.regular};
`

export const VideoIcon = styled(WatchIcon)`
  padding-right: 7px;
`

export const VideoLength = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  background-color: ${NeutralColors.White};
  border-radius: 12px;
  font-size: 13.5px;
  line-height: ${lineHeight.xSmall};
  padding: 5px 8px;
`
