import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import { content } from '../../layout/base.styles'

export const SearchContainer = styled.div`
  position: relative;
`

export const SearchInput = styled.input`
  width: 100%;
  color: ${NeutralColors.Ink};
  height: 55px;
  padding: 0;
  outline: none;
  border-radius: 0;
  border-style: none;
  border-bottom: 1px solid ${NeutralColors.Ink};
  background: none;
  font-family: inherit;
  -webkit-appearance: none !important;

  ${content.sizeL};

  &::placeholder {
    color: ${NeutralColors.Ink};
  }
`
