import { fontWeight } from '@typeform/ginger/dist/constants/typography'
import styled from 'styled-components'

import { content } from '../../layout/base.styles'

export const StyledRenderHtml = styled.div`
  grid-area: suggestionSnippet;

  ${content.sizeS};

  em {
    font-weight: ${fontWeight.medium};
  }
`
