import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocaleContext } from 'components/locale-context'
import { routes } from 'utils/routes'
import Collapse from 'components/atoms/common/collapse'

import {
  SectionHeadingContainer,
  SectionHeading,
  StyledArticle,
  StyledSection,
  StyledArticles,
  ArticleCount,
} from './section.styles'

const Section = ({ section, isActive }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { locale } = useLocaleContext()
  const handleClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  const { articles } = section
  const articleCount = articles.length
  return (
    <StyledSection>
      <SectionHeadingContainer onClick={handleClick}>
        <SectionHeading isActive={isActive}>{section.name}</SectionHeading>
        <ArticleCount>({articleCount})</ArticleCount>
      </SectionHeadingContainer>
      <Collapse isOpened={isOpen}>
        <StyledArticles role='menu'>
          {articles.map(article => {
            return (
              <StyledArticle key={article.id} role='presentation'>
                <a href={routes(locale).help.article(article)} role='menuitem'>
                  {article.title}
                </a>
              </StyledArticle>
            )
          })}
        </StyledArticles>
      </Collapse>
    </StyledSection>
  )
}

Section.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
      })
    ),
  }).isRequired,
  isActive: PropTypes.bool,
}

Section.defaultProps = {
  isActive: false,
}

export default React.memo(Section)
