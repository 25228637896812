import styled from 'styled-components'
import { Text } from '@typeform/ginger/dist/components/typography/text'
import { lineHeight } from '@typeform/ginger/dist/constants/typography'

export const StyledArticle = styled(Text)`
  list-style: none;
  margin: 0 0 10px;
  line-height: ${lineHeight.large};

  a:hover {
    font-weight: 900;
    letter-spacing: -0.027em;
  }
`
