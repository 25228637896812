import React from 'react'
import PropTypes from 'prop-types'

import { StyledHeadline, StyledContent } from './article.styles'

const Article = ({ content }) => {
  const { title, body } = content

  return (
    <>
      <StyledHeadline as='h1' size={2} data-testid='help-article-heading'>
        {title}
      </StyledHeadline>
      <StyledContent dangerouslySetInnerHTML={{ __html: body }} />
    </>
  )
}

Article.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
}

export default Article
