import { useCallback, useEffect } from 'react'

const useScrollTrigger = ({ element, isLoading }, callback = () => {}) => {
  const onScroll = useCallback(() => {
    const currentPosition = window.scrollY
    const refPosition = element.current?.scrollHeight - window.innerHeight

    if (currentPosition >= refPosition) {
      callback()
    }
  }, [element, callback])

  useEffect(() => {
    if (isLoading) {
      window.removeEventListener('scroll', onScroll)
    } else {
      window.addEventListener('scroll', onScroll)
    }
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll, isLoading])
}

export default useScrollTrigger
