import { NAMESPACES } from 'constants/locales'

import PropTypes from 'prop-types'
import { useEffect, useState, useCallback } from 'react'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'
import useTranslation from 'next-translate/useTranslation'

import VideoSection from '../video-section'

import {
  SectionContainer,
  Header,
  RemainingVideos,
} from './tutorial-section.styles'

const TutorialSection = ({ videos, children }) => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const [firstVideos, setFirstVideos] = useState([])
  const [remainingVideos, setRemainingVideos] = useState([])

  const organizeVideos = useCallback(() => {
    const columns = getNumberOfColumns(window.innerWidth)
    setFirstVideos(videos.slice(0, columns))
    setRemainingVideos(videos.slice(columns))
  }, [videos])

  const getNumberOfColumns = width => {
    if (width < BREAKPOINTS.sm) {
      return 1
    }
    if (width < BREAKPOINTS.md) {
      return 2
    }
    return 3
  }
  useEffect(() => {
    window.addEventListener('resize', organizeVideos)
    organizeVideos()
    return () => {
      window.removeEventListener('resize', organizeVideos)
    }
  }, [organizeVideos])

  return (
    <SectionContainer id='videos'>
      <Header>{t('help-center.section-header.tutorials')}</Header>
      <VideoSection videos={firstVideos} />
      {children}
      <RemainingVideos>
        <VideoSection videos={remainingVideos} />
      </RemainingVideos>
    </SectionContainer>
  )
}

TutorialSection.propTypes = {
  children: PropTypes.node.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      duration: PropTypes.string,
      article: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

TutorialSection.displayName = 'TutorialSection'

export default TutorialSection
