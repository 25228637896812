import { NAMESPACES } from 'constants/locales'

import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { trackClickEvent } from 'components/tracking'
import { routes } from 'utils'
import { useLocaleContext } from 'components/locale-context'
import { ButtonVariant } from 'components/atoms/button'
import { Palette } from '@typeform/ginger/dist/constants/palettes'

import Button from '../../button'

import { Wrapper } from './contact-support.styles'

const handleClick = () => {
  trackClickEvent({
    item: 'contact_support',
    item_type: 'button',
    page: window.location.href,
  })
}

const ContactSupport = () => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const { locale } = useLocaleContext()
  return (
    <Wrapper>
      <Button
        forwardedAs='a'
        data-gtm-event='cta_button'
        href={routes(locale).help.contact.path}
        variant={ButtonVariant.Secondary}
        onClick={handleClick}
        palette={Palette.Positive}
      >
        {t('help-center.contact-support.button')}
      </Button>
    </Wrapper>
  )
}

export default ContactSupport
