import styled from 'styled-components'
import {
  transparent,
  ForegroundColors,
  NeutralColors,
  UIPalette,
} from '@typeform/ginger/dist/constants/colors'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'

export const StyledContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${NeutralColors.White};
  position: fixed;
  z-index: 9999;
  display: flex;
  padding: 0 24px;
  color: ${props =>
    props.error ? ForegroundColors.CriticalDefault : UIPalette.Emerald};
  align-items: center;
  top: 0;
  right: 0;

  > svg {
    fill: ${NeutralColors.Ink};
  }

  ${mediaqueries.sm`
    top: 16px;
    right: 16px;
    max-width: 400px;
    padding: 0 32px;
    border-radius: ${BORDER_RADIUS.medium};
    border: 1px solid ${NeutralColors.Grey300};
  `}
`

export const StyledMessage = styled.p`
  display: flex;
  margin: 0;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: ${transparent};
  cursor: pointer;
  height: 41px;
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
`
