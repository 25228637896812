import styled from 'styled-components'
import GingerButton from 'components/atoms/button'

export const Button = styled(GingerButton)`
  padding: 12px 24px;

  /* TODO: Why is this not working on highlight video? */
  display: flex !important;
  align-items: center;
  justify-content: center;
  > div {
    margin-right: 8px;
  }
`
