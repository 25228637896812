import mediaQueries from '@typeform/ginger/dist/util/media-queries'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'

import { TSearchIconProps } from '../types'

export const SearchIconContainer = styled.div<TSearchIconProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ mobile }) =>
    mobile
      ? css`
          ${mediaQueries.md`
            display: none;
    `}
        `
      : css`
          @media (max-width: ${BREAKPOINTS.md - 1}px) {
            display: none;
          }
        `}
`
