import { useLocaleContext } from 'components/locale-context'
import { debounce } from '@s-libs/micro-dash'
import { useEffect, useMemo, useState, useCallback } from 'react'
import { API_BASE_URL } from 'services/zendesk/constants'
import { getZendeskLocale } from 'services/zendesk/utils'
import { routes } from 'utils'
import useCachedSWR from 'components/help/hooks/use-cached-swr'

const EMPTY_SEARCH_RESULTS = []
const EMPTY_QUERY = ''
const SEARCH_DEBOUNCE_PERIOD = 250
const SEARCH_MAX_RESULTS = 100
const SEARCH_MIN_QUERY_LENGTH = 3

const useHelpSuggestions = ({
  debouncePeriod = SEARCH_DEBOUNCE_PERIOD,
  maxResults = SEARCH_MAX_RESULTS,
  searchQuery,
}) => {
  const { locale } = useLocaleContext()
  const [query, setQuery] = useState(EMPTY_QUERY)
  const [isLoading, setIsLoading] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)

  const fetchParams = useMemo(
    () => ({
      locale,
      per_page: maxResults,
      query,
    }),
    [locale, query, maxResults]
  )

  const fetchCallbacks = useMemo(
    () => ({
      onStart: () => setIsLoading(true),
      onComplete: () => setIsLoading(false),
    }),
    []
  )

  const key = useMemo(
    () => [fetchParams, fetchCallbacks],
    [fetchCallbacks, fetchParams]
  )

  const { data } = useCachedSWR({
    initialValue: {
      searchResults: EMPTY_SEARCH_RESULTS,
      searchQuery: undefined,
    },
    fetcher,
    key,
  })

  const onQueryChange = useMemo(
    () => debounce(setQuery, debouncePeriod),
    [debouncePeriod]
  )

  useEffect(() => () => onQueryChange.cancel(), [onQueryChange])

  const helpSuggestions = useMemo(
    () => ({
      ...data,
      suggestions: data.searchResults.map(item => ({
        ...item,
        url: routes().help.article(item),
      })),
    }),
    [data]
  )

  useEffect(() => {
    setShowSuggestions(data.searchResults.length > 0)
  }, [data])

  useEffect(() => {
    if (searchQuery.length < SEARCH_MIN_QUERY_LENGTH) {
      setQuery(EMPTY_QUERY)
      return
    }
    onQueryChange(searchQuery)
  }, [onQueryChange, searchQuery])

  const hideSuggestions = useCallback(() => {
    setShowSuggestions(false)
  }, [setShowSuggestions])

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Escape') {
        hideSuggestions()
      }
    },
    [hideSuggestions]
  )

  useEffect(() => {
    if (showSuggestions) {
      window.addEventListener('click', hideSuggestions)
      window.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      window.removeEventListener('click', hideSuggestions)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [showSuggestions, hideSuggestions, handleKeyDown])

  return {
    ...helpSuggestions,
    isLoading,
    hasSuggestions: Boolean(helpSuggestions?.searchResults?.length),
    showSuggestions,
    setShowSuggestions,
  }
}

const fetcher = async (fetchParams, { onStart, onComplete }) => {
  if (fetchParams.query.length < SEARCH_MIN_QUERY_LENGTH) {
    return {
      searchResults: EMPTY_SEARCH_RESULTS,
      searchQuery: fetchParams.query,
    }
  }

  onStart()
  const { searchResults, error } = await fetchSearchResults(fetchParams)
  onComplete()

  if (error) {
    throw error
  }

  return {
    searchResults,
    searchQuery: fetchParams.query,
  }
}

const fetchSearchResults = async ({ per_page, query, locale }) => {
  const searchLocale = getZendeskLocale(locale)
  const url = new URL(`${API_BASE_URL}/help_center/articles/search`)
  url.searchParams.append('per_page', per_page)
  url.searchParams.append('query', query)
  url.searchParams.append('locale', searchLocale)

  let searchResults, error
  try {
    const response = await fetch(url.toString())
    searchResults = await response.json()
  } catch (e) {
    error = e
  }

  return {
    searchResults: searchResults?.results ?? EMPTY_SEARCH_RESULTS,
    error,
  }
}

export default useHelpSuggestions
