import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import { content } from '../../layout/base.styles'

export const StyledLink = styled.a`
  display: grid;
  gap: 0 24px;
  grid-template-columns: 1fr auto;
  padding: 8px 16px;
  grid-template-areas:
    'suggestionTitle suggestionIcon'
    'suggestionSnippet suggestionSnippet';

  ${content.sizeM};

  &:hover {
    background-color: ${NeutralColors.Grey100};
  }

  &:focus {
    background-color: ${NeutralColors.Grey100};
  }
`

export const StyledTitle = styled.div`
  grid-area: suggestionTitle;

  ${content.sizeL};
`
