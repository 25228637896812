import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import styled from 'styled-components'

export const StyledContainer = styled.section`
  margin: 40px 0;
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    top: 13px;
    right: 12px;
  }
`

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  padding: 10px 35px 10px 14px;

  /* Figma says 8px but PTG has 4px */
  border-radius: ${BORDER_RADIUS.small};
`
