import { useMemo } from 'react'
import { useLocaleContext } from 'components/locale-context'

import { QUERY_MIN_LENGTH } from '../../hub/constants/index'

const DEFAULT_PAGE = 1
export const DEFAULT_SIZE = 50 // 50 is the maximum for Zendesk.

const useHelpHubSearchUrl = ({
  query,
  filter = '',
  source = '',
  page = DEFAULT_PAGE,
  size = DEFAULT_SIZE,
}) => {
  const { locale } = useLocaleContext()
  return useMemo(
    () =>
      query?.length >= QUERY_MIN_LENGTH
        ? `/api/help/search/?page=${page}&size=${size}&locale=${locale}&query=${query}&source=${source}&filters=${filter}`
        : '',
    [locale, query, page, size, source, filter]
  )
}

export default useHelpHubSearchUrl
