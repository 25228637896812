import {
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import styled, { keyframes } from 'styled-components'
import Button, { ButtonElement } from 'components/atoms/button'
import { Section } from 'components/atoms/layout/'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

export const StyledGingerSection = styled(Section)`
  padding: 44px 0 0;
  min-height: 565px;
  background-color: ${NeutralColors.Ink};
  justify-content: flex-end;

  ${mediaqueries.md`
    padding-top: 100px;
  `}
`

export const StyledHeading = styled.h2`
  font-size: 2.7em;
  line-height: ${lineHeight.small};
  letter-spacing: 0;
  color: ${NeutralColors.White};
  font-weight: ${fontWeight.regular};
`

export const StyledTextContainer = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 70px;

  ${mediaqueries.sm`
    grid-column-start: 1;
    grid-column-end: 6;
    margin-bottom: 85px;
  `}
  ${mediaqueries.md`
    grid-column-start: 1;
    grid-column-end: 5;
    margin-bottom: 85px;
  `}
`

export const StyledPictureContainer = styled.div`
  grid-column: 1 / -1;

  img {
    max-width: 100%;
    width: 100%;
  }

  ${mediaqueries.sm`
    grid-column-start: 6;
    grid-column-end: -1;
  `}

  ${mediaqueries.md`
    grid-column-start: 5;
    grid-column-end: -1;
  `}
`

const modalAnim = keyframes`
  0% {
    opacity: 0.5;
    clip-path: circle(50px at 94.75% 8.5%);
  }

  100% {
    opacity: 1;
    clip-path: circle(150vmax at 94.75% 8.5%);
  }
`

export const VideoOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: ${NeutralColors.Ink};
  top: 0;
  left: 0;
  z-index: 1001;
  animation: 0.5s ${modalAnim} linear;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const buttonAnim = keyframes`
  0% {
    transform: scale(0.1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`

export const CloseOverlayButton = styled(Button)`
  position: fixed;
  top: 42px;
  right: 42px;
  min-width: unset;
  width: 32px;
  height: 32px;

  ${ButtonElement} {
    font-size: 75px;
    font-weight: ${fontWeight.regular};
    border: none;
    user-select: none;
    animation: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0.5s 1 normal both
      running ${buttonAnim};

    & svg {
      fill: ${NeutralColors.White};
      transform: scale(2);
    }
  }
`

export const StyledIframe = styled.iframe`
  width: 85vw;
  height: 48vw;
  border: none;
  outline: none;
`
