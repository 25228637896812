import styled from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

export const SectionContainer = styled.section`
  background-color: ${NeutralColors.GreyPaper};
  padding: 165px 0 64px;

  ${mediaQueries.md`
    padding: 207px 0 64px;
  `}
`

export const VideoContainer = styled.div`
  grid-column: 1 / span 12;
  padding-top: 56.25%; /* 16x9 aspect ratio */
  position: relative;
  animation: shimmer 8s infinite;
  background: linear-gradient(
    to right,
    ${NeutralColors.Grey300} 4%,
    ${NeutralColors.Grey300} 25%,
    ${NeutralColors.Grey300} 36%
  );
  background-size: 1000px 100%;

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`
export const Video = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`
