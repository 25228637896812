import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'

import { ZENDESK_EN_LOCALE } from './constants'

export const getZendeskLocale = locale => {
  if (/es/i.test(locale)) {
    return LANGUAGE_CODES.spanish
  }
  if (/en/i.test(locale)) {
    return ZENDESK_EN_LOCALE
  }
  return ZENDESK_EN_LOCALE
}
