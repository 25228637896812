import { NAMESPACES } from 'constants/locales'
import { COMMUNITY_BASE_URL } from 'constants/url'

import useTranslation from 'next-translate/useTranslation'
import React, { useCallback } from 'react'
import { trackClickEvent } from 'components/tracking'
import { ButtonVariant } from 'components/atoms/button'
import { Palette } from '@typeform/ginger/dist/constants/palettes'

import Button from '../../button'

import { Content, Header, Wrapper } from './community-banner.styles'

const CommunityBanner = () => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const handleClick = useCallback(() => {
    trackClickEvent({
      item: 'community',
      item_type: 'link',
      link_url: `${COMMUNITY_BASE_URL}/`,
      link_url_text: t('help-center.community-banner.button'),
      location: 'footer',
    })
  }, [t])
  return (
    <Wrapper>
      <Content>
        <Header>{t('help-center.community-banner.header')}</Header>
        <Button
          forwardedAs='a'
          data-gtm-event='cta_button'
          href={`${COMMUNITY_BASE_URL}/`}
          variant={ButtonVariant.Secondary}
          onClick={handleClick}
          palette={Palette.Positive}
        >
          {t('help-center.community-banner.button')}
        </Button>
      </Content>
    </Wrapper>
  )
}

export default CommunityBanner
