import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'
import { playVideo } from 'components/modified-kitt/icons'
import { Icon } from 'components/modified-kitt/icon'

import { IconContainer, IconText, IconSvg } from './suggestion-icon.styles'
import { SuggestionVideosContext } from './suggestion-videos-context'

const SuggestionIcon = ({ suggestion }) => {
  const videos = useContext(SuggestionVideosContext)

  const title = useMemo(() => {
    return videos.find(video => video.articleId === suggestion.id)?.duration
  }, [videos, suggestion.id])

  if (!title) {
    return null
  }

  return (
    <IconContainer>
      <IconSvg>
        <Icon svg={playVideo} />
      </IconSvg>
      <IconText>{title}</IconText>
    </IconContainer>
  )
}

SuggestionIcon.propTypes = {
  suggestion: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default SuggestionIcon
