const SESSION_STORAGE_SEARCH_ORIGIN_KEY = 'session-storage-search-origin-key'

export const persistSearchOrigin = searchOrigin => {
  try {
    window.sessionStorage.setItem(
      SESSION_STORAGE_SEARCH_ORIGIN_KEY,
      searchOrigin
    )
  } catch (error) {}
}

export const getPersistedSearchOrigin = () => {
  try {
    return window.sessionStorage.getItem(SESSION_STORAGE_SEARCH_ORIGIN_KEY)
  } catch (error) {}
  return null
}

export const clearPersistedSearchOrigin = () => {
  try {
    if (window.sessionStorage.getItem(SESSION_STORAGE_SEARCH_ORIGIN_KEY)) {
      window.sessionStorage.removeItem(SESSION_STORAGE_SEARCH_ORIGIN_KEY)
    }
  } catch (error) {}
}
