import { TARGET_PROP_TYPE } from 'constants/prop-types'

import React from 'react'
import PropTypes from 'prop-types'
import { useMainContext } from 'components/molecules/main-context'

import {
  Card,
  ImageContainer,
  InfoContainer,
  Title,
  VideoLength,
  VideoIcon,
} from './video-link-card.styles'

const VideoLinkCard = ({ href, title, image, duration, target, onClick }) => {
  const { t } = useMainContext()
  const url = new URL(image.url)

  url.searchParams.set('w', '700')

  return (
    <Card href={href} target={target} onClick={onClick}>
      <ImageContainer src={url.toString()} />
      <InfoContainer>
        <Title>{title}</Title>
        <VideoLength>
          <VideoIcon title={t('link-card.video-icon-alt')} />
          {duration}
        </VideoLength>
      </InfoContainer>
    </Card>
  )
}

VideoLinkCard.displayName = 'VideoLinkCard'

VideoLinkCard.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  duration: PropTypes.string,
  target: TARGET_PROP_TYPE,
  onClick: PropTypes.func,
  locale: PropTypes.string.isRequired,
}

VideoLinkCard.defaultProps = {
  duration: null,
  target: '_self',
  onClick: () => {},
}

export default VideoLinkCard
