import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import {
  StyledSection,
  StyledArticle,
  StyledAside,
  StyledCategoryTitle,
  StyledCategoryContainer,
  StyledVerticalCaret,
  TextButton,
} from './two-column.styles'

// Implicit first child is the article and the second child is the aside
// Displays the aside before the article medium or larger width
// Displays the article above the aside in small width
const TwoColumn = ({ children }) => {
  const [article, aside] = children
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  return (
    <StyledSection>
      <StyledArticle>{article}</StyledArticle>

      <StyledAside>
        <StyledCategoryTitle>
          <TextButton onClick={toggleIsOpen}>Categories</TextButton>
          <StyledVerticalCaret active={isOpen} />
        </StyledCategoryTitle>
        <StyledCategoryContainer isOpen={isOpen}>
          {aside}
        </StyledCategoryContainer>
      </StyledAside>
    </StyledSection>
  )
}

TwoColumn.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TwoColumn
