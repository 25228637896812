import { useEffect, useCallback, useState } from 'react'

const useListKeyboardNav = (listLength, isVisible, initIndex = null) => {
  const [activeIndex, setActiveIndex] = useState(initIndex)

  const handleKeyPress = useCallback(
    e => {
      let delta = null
      if ((e.key === 'Tab' && !e.shiftKey) || e.key === 'ArrowDown') {
        delta = activeIndex === null ? 0 : 1
      }
      if ((e.key === 'Tab' && e.shiftKey) || e.key === 'ArrowUp') {
        delta = -1 + listLength
      }
      if (delta !== null) {
        e.preventDefault()
        setActiveIndex(prev => (prev + delta) % listLength)
      }
    },
    [setActiveIndex, activeIndex, listLength]
  )

  //Reset index if list is no longer visible
  useEffect(() => {
    if (!isVisible) {
      setActiveIndex(null)
    }
  }, [isVisible])

  useEffect(() => {
    if (isVisible && listLength) {
      window.addEventListener('keydown', handleKeyPress)
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [isVisible, listLength, handleKeyPress])

  return activeIndex
}

export default useListKeyboardNav
