import styled from 'styled-components'
import { getStaticAsset } from 'utils/assets'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { UIPalette } from '@typeform/ginger/dist/constants/colors'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'

const getBackgroundImagePath = size =>
  getStaticAsset(
    `help-page/community-banner/community-banner-shape-${size}.svg`
  )

export const Wrapper = styled.section`
  background-color: ${UIPalette.Orange300};
  padding: 120px 0;
  transition: 0.5s all;

  ${mediaQueries.sm`
    padding: 52px 0;
  `}

  ${mediaQueries.md`
    padding: 27px 0;
  `}
`

export const Content = styled.div`
  background-image: url(${getBackgroundImagePath('xs')});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 80px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    min-width: 170px;
  }

  ${mediaQueries.sm`
    background-image: url(${getBackgroundImagePath('sm')});
    padding: 58px 152px;
  `}

  ${mediaQueries.md`
    background-image: url(${getBackgroundImagePath('md')});
    padding: 73px 200px;
  `}
`

export const Header = styled.h2`
  font-size: ${fontSize.x2Large};
  font-weight: ${fontWeight.regular};
  line-height: ${lineHeight.xSmall};
  letter-spacing: -0.005em;
  margin: 0 auto 32px;
  max-width: 296px;

  ${mediaQueries.sm`
    max-width: 352px;
  `}

  ${mediaQueries.md`
    margin: 0 auto 24px auto;
    max-width: unset;
  `}
`
