import { NAMESPACES } from 'constants/locales'

import React, { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import qs from 'qs'
import useTranslation from 'next-translate/useTranslation'
import { useEffectOnce } from 'react-use'

import {
  StyledContainer,
  StyledMessage,
  StyledCloseButton,
} from './ticket-created-notification.styles'
import { CheckIcon } from './check-icon'
import { CloseIcon } from './close-icon'

const NOTIFICATION_TIMEOUT = 10000
export const TICKET_CREATE_QUERYSTRING = 'ticket-created'

// This is a smart component that will make itself disappear after the timeout.
export const TicketCreatedNotification = () => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const router = useRouter()

  // Only show the ticket created notification if the querystring is there.
  const [showTicketCreatedNotification, setShowTicketCreatedNotification] =
    useState(router.query?.hasOwnProperty(TICKET_CREATE_QUERYSTRING))

  const removeNotification = useCallback(() => {
    if (showTicketCreatedNotification) {
      setShowTicketCreatedNotification(false)
      const { query, pathname } = router
      const newQuery = { ...query }
      delete newQuery[TICKET_CREATE_QUERYSTRING]
      const newPath = `${pathname}${qs.stringify(newQuery, {
        addQueryPrefix: true,
      })}`
      router.replace(newPath, undefined, { shallow: true })
    }
  }, [router, showTicketCreatedNotification])

  const handleClickClose = useCallback(
    () => removeNotification(),
    [removeNotification]
  )

  useEffectOnce(() => {
    setTimeout(() => removeNotification(), NOTIFICATION_TIMEOUT)
  })

  if (!showTicketCreatedNotification) {
    return null
  }

  return (
    <StyledContainer data-testid='ticket-created-container'>
      <StyledMessage>
        <CheckIcon />
        {t('help-center.ticket-created.notification')}
      </StyledMessage>
      <StyledCloseButton onClick={handleClickClose}>
        <CloseIcon />
      </StyledCloseButton>
    </StyledContainer>
  )
}
