import styled from 'styled-components'
import { ButtonElement } from 'components/atoms/button'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'
import { SearchIcon } from 'components/molecules/search/search-bar/search-icon'
const BACKDROP_ZINDEX = 1020
export const StyledNav = styled.nav`
  margin-bottom: 48px;
  display: inline-flex;
  flex-direction: column;
  ul,
  li {
    list-style: none;
  }
`

export const StyledSections = styled.ul`
  padding: 0;
  margin: 0;
`
export const SearchContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  z-index: ${BACKDROP_ZINDEX + 1};
`
export const StyledHCSearchButton = styled(ButtonElement)`
  display: flex;
  align-items: center;
  color: #737373;
  margin: 16px auto;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`
export const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 8px;
  color: #262627;
`
export const BackgroundDropContainer = styled.div`
  position: fixed;
  background-color: #0d0d0db2;
  backdrop-filter: blur(0.5px);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: ${BACKDROP_ZINDEX};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
  ${props => !props.isOpen && 'pointer-events: none;'}
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 200px auto 1fr;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`
export const SearchWrapper = styled.div`
  grid-column: 3 / span 8;
  grid-row: 2;
  width: 100%;
`
