import styled from 'styled-components'
import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { BOX_SHADOWS } from '@typeform/ginger/dist/constants/shadows'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'

export const StyledContent = styled.p`
  margin-bottom: 0;
`
export const StyledCta = styled.a`
  text-decoration: underline;
`

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${GRID_UNIT * 3}px;

  svg {
    width: ${GRID_UNIT * 4}px;
    height: ${GRID_UNIT * 4}px;
  }

  ${mediaQueries.sm`
    margin-bottom: 0;
    margin-right: ${GRID_UNIT * 4}px;
  `}
`

export const PopupWrapper = styled.aside`
  min-height: ${GRID_UNIT * 2}px;
  margin: ${GRID_UNIT * 7}px 0;

  > div {
    transform: translateY(0%);
    min-height: ${GRID_UNIT * 12}px;
    background: ${NeutralColors.White};
    box-shadow: ${BOX_SHADOWS.small}, ${BOX_SHADOWS.medium};
    border-radius: ${GRID_UNIT}px;
    align-items: center;
    max-width: 820px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: ${GRID_UNIT * 3}px ${GRID_UNIT * 3}px ${GRID_UNIT * 3}px
      ${GRID_UNIT * 4}px;
  }

  ${mediaQueries.sm`
    > div {
      flex-direction: row;
      .alert-pop-up-sign {
        margin-bottom: 0;
        margin-right: ${GRID_UNIT * 4}px;
      }
    }
 `}
`
