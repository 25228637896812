import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

export const Wrapper = styled.section`
  background: ${NeutralColors.GreyPaper};
  margin: 0;
  padding: 0 24px;
  width: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
