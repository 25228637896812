import { useState, useMemo, useCallback, useEffect } from 'react'
import useCachedSWR from 'components/help/hooks/use-cached-swr'
import useHelpHubSearchUrl from 'components/help/hooks/use-help-hub-search-url'

import { DEFAULT_RESULT_DATA } from '../../hub/constants/index'

const defaultFetcher = url => fetch(url).then(r => r.json())

const useHelpHubResults = ({
  query = '',
  filter = '',
  source = '',
  fetcher = defaultFetcher,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [requestUrl, setRequestUrl] = useState('')
  const [results, setResults] = useState([])
  const [resultParams, setResultParams] = useState({})

  const firstPageUrl = useHelpHubSearchUrl({
    query,
    filter,
    source,
  })

  useEffect(() => {
    setRequestUrl(firstPageUrl)
    setResults([])
  }, [firstPageUrl])

  const {
    data: { results: newResults, next_page },
    error,
  } = useCachedSWR({
    initialValue: DEFAULT_RESULT_DATA,
    fetcher: async request => {
      setIsLoading(true)
      const result = await fetcher(request)
      setIsLoading(false)
      setResultParams({
        query,
        filter,
        source,
      })
      return result
    },
    key: requestUrl,
    options: {
      revalidateOnFocus: false,
    },
  })

  useEffect(() => {
    setResults(oldResults => {
      const urls = oldResults.map(r => r.url)
      const unique = [...newResults].filter(m => urls.indexOf(m.url) === -1)
      return [...oldResults, ...unique]
    })
  }, [newResults])

  const getNextPage = useCallback(() => {
    if (!next_page) {
      return
    }

    setRequestUrl(next_page)
  }, [next_page])

  const isEmpty = useMemo(
    () => !requestUrl || !Boolean(results?.length),
    [results, requestUrl]
  )

  return {
    isLoading,
    isEmpty,
    results,
    error,
    getNextPage,
    resultParams,
  }
}

export default useHelpHubResults
