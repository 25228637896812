import { NAMESPACES } from 'constants/locales'

import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useMemo } from 'react'
import { searchHighlightRegex } from 'utils/search-hightlight-regex'
import { usePrevious } from 'react-use'
import { shouldTrackSearchQueryInput } from 'utils/tracking'

import { RESULT_PROP_TYPES } from '../constants/prop-types'
import { QUERY_MIN_LENGTH, SOURCES_KEYS } from '../constants'
import { helpHubSearchQueryEntered } from '../tracking'

import {
  StyledSearchResultWrapper,
  StyledSearchResultMessage,
} from './search-results.styles'
import SearchResultCategory from './search-result-category'

const SearchResults = ({
  results,
  open,
  query,
  isLoading,
  isEmpty,
  location,
}) => {
  const { t } = useTranslation(NAMESPACES.HELP)

  const highlightRegex = useMemo(
    () => searchHighlightRegex(query, QUERY_MIN_LENGTH),
    [query]
  )

  const numResults = results?.length || 0
  const prevNumResults = usePrevious(numResults)
  useEffect(() => {
    if (numResults && query && prevNumResults !== numResults) {
      const payload = {
        search_query: query,
        number_of_results: numResults,
        location,
      }
      if (shouldTrackSearchQueryInput(query)) {
        helpHubSearchQueryEntered(payload)
      }
    }
  }, [prevNumResults, numResults, query, location])

  const groups = useMemo(() => {
    if (!results) {
      return {}
    }
    return results.reduce((prev, curr) => {
      if (!prev[curr.source]) {
        prev[curr.source] = []
      }
      prev[curr.source].push(curr)
      return prev
    }, {})
  }, [results])

  return (
    <StyledSearchResultWrapper open={open && query?.length >= QUERY_MIN_LENGTH}>
      {/* TODO: Remove the && and following when implementing WEB-3962  */}
      {isLoading && (
        <StyledSearchResultMessage>
          {t('help-hub.search.searching')}
        </StyledSearchResultMessage>
      )}
      {!isLoading && isEmpty && (
        <StyledSearchResultMessage>
          {t('help-hub.search.empty')}
        </StyledSearchResultMessage>
      )}

      {!isLoading &&
        !isEmpty &&
        Object.keys(groups).map(g => (
          <SearchResultCategory
            key={g}
            query={query}
            title={SOURCES_KEYS[g]}
            results={groups[g]}
            highlightRegex={highlightRegex}
          />
        ))}
    </StyledSearchResultWrapper>
  )
}

SearchResults.defaultProps = {
  query: null,
}

SearchResults.propTypes = {
  results: PropTypes.arrayOf(RESULT_PROP_TYPES).isRequired,
  open: PropTypes.bool.isRequired,
  query: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
}

export default SearchResults
