import styled from 'styled-components'
import { Headline, Text } from '@typeform/ginger'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { transparent } from '@typeform/ginger/dist/constants/colors'

import {
  XS_SECTION_VERTICAL_SPACING,
  SM_SECTION_VERTICAL_SPACING,
  MD_SECTION_VERTICAL_SPACING,
} from '../links-section.styles'

export const StyledSection = styled.section`
  grid-column: 1/-1;
  margin-bottom: ${XS_SECTION_VERTICAL_SPACING};

  ${mediaQueries.sm`
    grid-column: span 4;
    margin-bottom: ${SM_SECTION_VERTICAL_SPACING};
  `}
  ${mediaQueries.md`
    margin-bottom: ${MD_SECTION_VERTICAL_SPACING};
  `}
  ul,
  li {
    list-style: none;
    padding: 0;
  }
`

export const StyledList = styled.ul`
  overflow: hidden;
  margin: 0;
`

export const StyledToggle = styled(Text)`
  padding-left: 0;
  background-color: ${transparent};
  border: none;
  text-decoration: underline;
  cursor: pointer;
`

export const StyledHeadline = styled(Headline)`
  max-width: 290px;
  cursor: pointer;
`

export const StyledArticleList = styled.ul`
  margin: 0;
`
