import { TARGET_PROP_TYPE } from 'constants/prop-types'

import PropTypes from 'prop-types'

export const CTA_PROP_TYPES = PropTypes.shape({
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  target: TARGET_PROP_TYPE,
  onClick: PropTypes.func,
})

export const RESULT_PROP_TYPES = PropTypes.shape({
  description: PropTypes.string.isRequired,
  source: PropTypes.oneOf(['zendesk', 'insided']).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['article', 'video', 'post']).isRequired,
  url: PropTypes.string.isRequired,
})
