import React, { isValidElement } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SVGInline from 'react-svg-inline'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

const IconRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  svg {
    display: block;
    fill: ${({ color }) => color || NeutralColors.Ink};
  }
`

export const Icon = ({ svg, color }) => {
  const svgIcon = isValidElement(svg) ? svg : <SVGInline svg={svg} />
  return <IconRoot color={color}>{svgIcon}</IconRoot>
}

Icon.propTypes = {
  svg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  color: PropTypes.string,
}

Icon.defaultProps = {
  color: null,
}
