import { NAMESPACES } from 'constants/locales'

import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import Button, { ButtonVariant } from 'components/atoms/button'
import { Palette } from '@typeform/ginger/dist/constants/palettes'

import { Wrapper } from './see-more-tutorials.styles'

export const SeeMoreTutorials = ({ isActive, onClick }) => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const buttonText = isActive
    ? t('help-center.home.see-fewer-tutorials')
    : t('help-center.home.see-more-tutorials')
  return (
    <Wrapper>
      <Button
        data-gtm-event='cta_button'
        onClick={onClick}
        variant={ButtonVariant.Secondary}
        palette={Palette.Positive}
      >
        {buttonText}
      </Button>
    </Wrapper>
  )
}

SeeMoreTutorials.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
