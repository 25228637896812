import { NAMESPACES } from 'constants/locales'

import { useHelpSuggestions } from 'components/help/hooks'
import React, { useState, useEffect } from 'react'
import { trackEvent } from 'components/tracking'
import useTranslation from 'next-translate/useTranslation'
import { shouldTrackSearchQueryInput } from 'utils/tracking'

import { SearchContainer, SearchInput } from './search.styles'
import Suggestions from './suggestions'

const NUMBER_OF_SUGGESTIONS = 5

const Search = () => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const [query, setQuery] = useState('')

  const { suggestions, searchQuery, showSuggestions, setShowSuggestions } =
    useHelpSuggestions({
      maxResults: NUMBER_OF_SUGGESTIONS,
      searchQuery: query,
    })

  useEffect(() => {
    if (!searchQuery) {
      return
    }
    if (shouldTrackSearchQueryInput(searchQuery)) {
      trackEvent('search_query_entered', {
        search_query: searchQuery,
        location: 'main_page',
        page: window.location.href,
        number_of_results: suggestions.length,
      })
    }
  }, [searchQuery, suggestions.length])

  const handleSearchInputClick = e => {
    e.stopPropagation()
    setShowSuggestions(suggestions.length > 0)
  }

  return (
    <SearchContainer>
      <form
        role='search'
        autoComplete='off'
        acceptCharset='UTF-8'
        action='/help/search/'
      >
        <SearchInput
          onClick={handleSearchInputClick}
          name='q'
          placeholder={t('help-center.hero.placeholder')}
          autoComplete='off'
          aria-label={t('help-center.hero.placeholder')}
          onChange={e => setQuery(e.target.value)}
        />
      </form>
      <Suggestions
        showSuggestions={showSuggestions}
        suggestions={suggestions}
      />
    </SearchContainer>
  )
}

Search.propTypes = {}

export default Search
