import { fontWeight } from '@typeform/ginger/dist/constants/typography'
import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import { content } from '../../layout/base.styles'

export const SuggestionContainer = styled.div`
  background-color: ${NeutralColors.White};
  border-radius: 5px;
  box-shadow: 0 4px 15px rgb(0 0 0 / 15%);
  margin-top: 8px;
  max-width: 820px;
  position: absolute;
  z-index: 20;
  width: 100%;
`

export const SuggestionHeader = styled.div`
  border-bottom: 1px solid ${NeutralColors.Grey200};
  color: ${NeutralColors.Ink};
  font-weight: ${fontWeight.regular};
  padding: 16px;

  ${content.sizeS};
`
