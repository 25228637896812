import { hasFunctionalConsent } from '@typeform/js-tracking/lib/consents/consentUtil'
import { useCallback } from 'react'

const ARTICLE_HISTORY_KEY = 'hc:article_history'

const getArticlesArray = () => {
  const articleHistoryString = localStorage.getItem(ARTICLE_HISTORY_KEY) ?? '[]'
  return JSON.parse(articleHistoryString)
}

export const getLastFiveUniqueArticles = (articleTitle, articleHistory) => {
  //Remove title from array if it already exists to prevent duplicates
  const filteredArticleHistory = articleHistory.filter(
    title => title !== articleTitle
  )
  filteredArticleHistory.unshift(articleTitle)
  return filteredArticleHistory.slice(0, 5)
}

const useArticleHistory = () => {
  const addArticleHistory = articleTitle => {
    //Make sure we can track and have a title
    if (!hasFunctionalConsent() || !articleTitle) {
      return
    }
    const articleHistory = getArticlesArray()
    const lastFiveArticles = getLastFiveUniqueArticles(
      articleTitle,
      articleHistory
    )
    localStorage.setItem(ARTICLE_HISTORY_KEY, JSON.stringify(lastFiveArticles))
  }

  const getArticleHistory = useCallback(() => {
    const articleHistory = getArticlesArray()
    return articleHistory.join('\n\r')
  }, [])

  return { getArticleHistory, addArticleHistory }
}

export default useArticleHistory
