import { NAMESPACES } from 'constants/locales'

import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'
import { caretDown, caretUp } from 'components/modified-kitt/icons'
import { Icon } from 'components/modified-kitt/icon'
import { useState, useMemo } from 'react'

import { RESULT_PROP_TYPES } from '../constants/prop-types'

import {
  CategoryTitle,
  CategoryMore,
  GroupList,
} from './search-result-category.styles'
import SearchResultLink from './search-result-link'

const COLLAPSED_LINK_COUNT = 3

const SearchResultCategory = ({ title, results, highlightRegex, query }) => {
  const { t } = useTranslation(NAMESPACES.HELP)
  const [isExpanded, setIsExpanded] = useState(false)

  const hasMore = useMemo(
    () => results.length > COLLAPSED_LINK_COUNT,
    [results]
  )

  const visibleResults = useMemo(
    () =>
      !hasMore || isExpanded ? results : results.slice(0, COLLAPSED_LINK_COUNT),
    [isExpanded, results, hasMore]
  )

  return (
    <>
      <CategoryTitle>
        <span>{t(title)}</span>
        {hasMore && (
          <CategoryMore onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded
              ? t('help-hub.search.category.less')
              : t('help-hub.search.category.more')}
            {isExpanded ? <Icon svg={caretUp} /> : <Icon svg={caretDown} />}
          </CategoryMore>
        )}
      </CategoryTitle>
      <GroupList>
        {visibleResults.map(r => (
          <SearchResultLink
            key={r.url}
            query={query}
            result={r}
            highlightRegex={highlightRegex}
          />
        ))}
      </GroupList>
    </>
  )
}

SearchResultCategory.defaultProps = {
  highlightRegex: null,
  query: '',
}

SearchResultCategory.propTypes = {
  results: PropTypes.arrayOf(RESULT_PROP_TYPES).isRequired,
  title: PropTypes.string.isRequired,
  highlightRegex: PropTypes.object,
  query: PropTypes.string,
}

export default SearchResultCategory
