import React from 'react'
import PropTypes from 'prop-types'
import { routes } from 'utils/routes'
import { useLocaleContext } from 'components/locale-context'

import { StyledArticle } from './article.styles'

export const Article = ({ article }) => {
  const { locale } = useLocaleContext()
  return (
    <StyledArticle as='li' size={1}>
      <a href={routes(locale).help.article(article)}>{article.title}</a>
    </StyledArticle>
  )
}

Article.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
  }).isRequired,
}
