import { string } from 'prop-types'
import WistiaPlayer from 'components/molecules/wistia-player'
import { GridContainer } from 'components/atoms/layout/'

import {
  SectionContainer,
  VideoContainer,
  Video,
} from './article-video-section.styles'

const ArticleVideoSection = ({ videoId }) => {
  if (!videoId) {
    return null
  }
  return (
    <SectionContainer>
      <GridContainer>
        <VideoContainer>
          <Video>
            <WistiaPlayer videoId={videoId} />
          </Video>
        </VideoContainer>
      </GridContainer>
    </SectionContainer>
  )
}

ArticleVideoSection.propTypes = {
  videoId: string,
}

ArticleVideoSection.defaultProps = {
  videoId: null,
}

ArticleVideoSection.displayName = 'ArticleVideoSection'

export default ArticleVideoSection
